body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* fonts */
.f-1_5rem {
  font-size:1.5rem !important;
}

.f-5rem {
  font-size:5rem !important;
}

.f-700 {
  font-weight:700 !important;
}

.f-500 {
  font-weight:500 !important;
}

.fullscreen {
  height:100vh;
  width:100vw;
}

.f-h1 {
  font-size: clamp(24px,7vw,50px);
}

.f-h5 {
  font-size: clamp(12px,3vw,24px);
}

.f-quicksand {
  font-family: 'Quicksand';
}


/* colors */
.white {
  color : white !important;
}

.darkslategray {
  color : darkslategray !important;
}